@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard', sans-serif;
    src: url('MazzardH-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
