@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url('Roboto-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
