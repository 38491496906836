@import "assets/fonts/Roboto/Roboto.scss";
@import "./assets/fonts/Mazzard/Mazzard.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.g-padding {
  padding: 16px;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.m-0 {
  margin: 0 !important;
}

.min-height-300px {
  min-height: 300px;
}

.b-rdc-name {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
  font-style: italic;

  p {
    text-align: right;
  }

  &__icon {
    width: 22px;
    height: 20px;
  }
}

.b-notFoundBlock {
  display: grid;
  gap: 24px;
  place-items: center;
  text-align: center;
  font: 400 16px/24px 'Roboto', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.b-table-wrapper__overflow-scroll {
  overflow-y: scroll;
}

@media (max-width:575.98px) {
  .b-notFoundBlock {
    svg {
      width: 200px;
    }
  }
}
